body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	width: 100%;
	height: 100%;
	overflow: hidden;

	font-family: "Lora", serif;
}

body > div {
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

*:focus {
	outline: none;
}
